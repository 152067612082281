<template>
    <v-container fluid>
        <v-row d-block class="mb-3">
            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <v-card>

                    <v-toolbar color="cyan" dark dense>
                        <v-toolbar-title>Instrucciones para Importar</v-toolbar-title>
                    </v-toolbar>

                    <v-list >
                        <template v-for="(item, index) in instrucciones">

                            <v-divider
                                v-if="item.divider"
                                :key="index"
                                :inset="item.inset">
                            </v-divider>

                            <v-list-item :key="item.instruccion">
                                <v-list-item-avatar size="28" color="info">
                                    <span class="white--text ">{{ index + 1 }}</span>
                                </v-list-item-avatar>

                                <v-list-item-content>
                                    <v-list-item-title
                                        v-html="item.instruccion"></v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                    </v-list>
                </v-card>
            </v-col>

            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <v-card>
                    <v-card-text>

                        <v-form ref="form" v-model="valid" enctype="multipart/form-data">

                            <v-select
                                v-model="importarModel.unidad_operativa_id"
                                :label="importarForm.unidad_operativa_id.label"
                                :items="unidadesOperativas"
                                item-text="unidad_operativa"
                                item-value="unidad_operativa_id"
                                :rules="importarForm.unidad_operativa_id.rules"
                                prepend-icon="checklist"
                            />

                            <v-file-input
                                truncate-length="50"
                                :label="importarForm.archivo.label"
                                :placeholder="importarForm.archivo.placeholder"
                                v-model='importarModel.archivo'
                                :rules="importarForm.archivo.rules"
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                prepend-icon="description"
                            ></v-file-input>
                        </v-form>

                    </v-card-text>
                    <v-divider class="mt-1"></v-divider>
                    <v-card-actions class="justify-center py-3">

                        <v-btn small outlined @click="regresar">
                            <v-icon>arrow_back</v-icon>
                            Regresar
                        </v-btn>

                        <v-btn small outlined color="warning" @click="resetForm()">
                            <v-icon>restart_alt</v-icon>
                            Limpiar
                        </v-btn>

                        <v-btn small color="primary" :loading="loading" @click.stop="importar()">
                            <v-icon>upload_file</v-icon>
                            Importar
                        </v-btn>

                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>

        <v-dialog
            v-model="showModalImportar"
            persistent
            fullscreen>
            <v-card>
                <v-card-title class="text-h5 pt-10">
                    Importando registros, no cierre la ventana. Esto puede durar varios minutos, espere a que termine el
                    proceso
                </v-card-title>

                <v-card-text>
                    <v-progress-linear
                        indeterminate
                        color="info"
                    ></v-progress-linear>
                </v-card-text>

            </v-card>

        </v-dialog>
    </v-container>
</template>

<script>

import Auth from "@/services/auth";

export default {
    name: "AdministracionImportacionesNuevo",
    data() {
        return {
            permisos: [],
            unidadesOperativas: [],
            valid: true,
            loading: false,
            importarModel: {
                unidad_operativa_id: null,
                archivo: null,
            },
            showModalImportar: false,
            importarForm: {
                unidad_operativa_id: {
                    label: 'Unidad Operativa',
                    placeholder: 'Seleccione',
                    rules: [(v) => !!v || 'Campo requerido'],
                },
                archivo: {
                    label: 'Archivo Excel (.xlsx)',
                    placeholder: 'Elija el archivo formato Excel (.xlsx)',
                    rules: [(v) => !!v || 'Campo requerido'],
                },
            },
            instrucciones: [
                {
                    instruccion: " <a href='/documentos/Plantilla_Cargar_Cursos_Terminados.xlsx'>Descargar aquí la Plantilla de Importación</a>",
                    divider: false,
                    inset: false
                },
                {
                    instruccion: "Siga las instrucciones que vienen en el archivo en la hoja <b>Notas</b> y rellene con la data.",
                    divider: true,
                    inset: true
                },
                {
                    instruccion: "Seleccione la <b>Unidad Operativa</b> para vincular los registros importados.",
                    divider: true,
                    inset: true
                },
                {
                    instruccion: "Elija el archivo Excel (.xlsx).",
                    divider: true,
                    inset: true
                },
                {
                    instruccion: "Pulse en el botón <b>IMPORTAR</b>.",
                    divider: true,
                    inset: true
                },
                {
                    instruccion: "Si aparece algún Aviso o Mensaje, siga las instrucciones e inicie nuevamente.",
                    divider: true,
                    inset: true
                }
            ]
            /////
        }
    },
    computed: {
        // DO some
    },
    methods: {
        regresar() {
            this.$router.go(-1)
        },
        importar() {
            // Validar
            if (this.$refs.form.validate()) {

                if (!this.importarModel.archivo) {
                    this.$toast.warning("Seleccione el archivo formato Excel (.xlsx).");
                    return;
                }

                let formData = new FormData;
                formData.append("archivo", this.importarModel.archivo);
                formData.append("unidad_operativa_id", this.importarModel.unidad_operativa_id);

                this.loading = true;
                this.showModalImportar = true;
                let url = "/importaciones/importar";

                window.axios.post(url, formData).then(response => {
                    if (response.data.success) {

                        this.$toast.success(response.data.message, {
                            timeout: 10000,
                        });

                        this.regresar();

                    } else if (response.data.success === false) {

                        this.$toast.error(response.data.message, {
                            position: "top-center",
                            timeout: 60000,
                            hideProgressBar: true,
                            closeButton: "button",
                            icon: true
                        });
                    }
                })
                    .catch(error => {
                        console.log(error)
                        this.$toast.error("No se pudo Importar.", {
                            position: "top-center",
                            timeout: 60000,
                            hideProgressBar: true,
                            closeButton: "button",
                            icon: true
                        });
                    })
                    .finally(() => {
                        // Do some
                        this.loading = false;
                        this.showModalImportar = false;
                        // Siempre mandar a reset el form y los datos para que vuelva a cargar el archivo
                        this.resetForm();
                    })
            } else {
                this.$toast.warning("Complete la información requerida.");
            }

        },
        getImportancionesCreate() {
            this.loading = true;

            // Get data;

            window.axios.get('/importaciones/create', {}).then(response => {
                if (response.data.success) {

                    this.unidadesOperativas = response.data.data.unidadesOperativas;

                } else if (response.data.success === false) {
                    this.$toast.error(response.data.message);
                }
            })
                .catch(error => {
                    console.log(error)
                    this.$toast.error("No se pudo obtener los datos para continuar.");
                })
                .finally(() => {
                    // Do some
                    this.loading = false;
                })
        },
        resetForm() {
            this.$refs.form.reset();
        }

    },
    created() {
        Auth.checkPermisos("/administracion/importaciones").then((res) => {
            this.permisos = res;

            if(!this.permisos[8]) {
                this.regresar();
            }

            this.getImportancionesCreate();
        });
    }
}
</script>

<style>

</style>
